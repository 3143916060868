import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Header from "../Header"
import Footer from "../Footer"
import SEO from "../SEO"
import "semantic-ui-less/semantic.less"
import "./layout.css"
import { GenderContext } from "../../providers/GenderProvider"
import cookieslogo from "../../images/iconbig.png"

const Container = styled.div`
  & .acceptCookie {
    background: ${props => props.theme.colors.green} !important;
    color: #fff !important;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 4px !important;
    padding: 12px 28px !important;
  }
`

const Main = styled.main`
  position: relative;
  padding: 0 22px;
`

const LinkToCookies = styled.a`
  width: 24px;
  height: 24px;
  display: inline-block;
  margin: 0;
`

const Layout = ({ title, children, isSticky, withStores, description }) => {
  const { setGender } = useContext(GenderContext)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (window.location.href.match("herre")) {
      setGender("male")
    }

    if (window.location.href.match("dame")) {
      setGender("female")
    }
  }, [])

  return (
    <>
      <SEO title={title || "Dit online outlet"} description={description} />
      <Container>
        <Header isSticky={isSticky} siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
          }}
        >
          <Main>{children}</Main>
        </div>
        <Footer withStores={withStores} />
      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
