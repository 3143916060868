import React from "react"
import { Grid } from "semantic-ui-react"
import styled from "styled-components"
import Slider from "react-slick"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { device } from "../../theme"
import { partners } from "../../partners"
import { throttle } from "../../utils"

const Container = styled.div`
  background: #fff;
  padding: 32px 0 150px;
`

const SVG = styled.svg`
  margin-bottom: -10px;
  margin-top: -142px;
`

const Store = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px 32px;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media ${device.phone} {
    padding: 12px;
  }
`

const StyledImage = styled(LazyLoadImage)`
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
`

const Column = styled(Grid.Column)`
  flex-basis: 15%;
  height: 110px;

  @media ${device.tablet} {
    flex-basis: 33.333%;
  }
`

const Title = styled.h3`
  text-align: center;
  font-size: 2rem;
`

function Brands() {
  return (
    <>
      <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#fff"
          fillOpacity="1"
          d="M0,192L120,208C240,224,480,256,720,256C960,256,1200,224,1320,208L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </SVG>
      <Container className="brands-slider">
        <Title>
          Forhandlere du kan finde tøj fra
        </Title>
        <Store>
          {partners.map(partner => (
            <Column key={partner.page}>
              <Store>
                <StyledImage
                  src={partner.logo}
                  alt={`${partner.partner} logo`}
                />
              </Store>
            </Column>
          ))}
        </Store>
      </Container>
    </>
  )
}

export default Brands
