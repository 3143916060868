import React, { useContext } from "react"
import styled from "styled-components"
import { Grid } from "semantic-ui-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GenderContext } from "../../providers/GenderProvider"
import { FilterContext } from "../../providers/FilterProvider"
import Brands from "../Brands"
import { device } from "../../theme"

const Wrapper = styled.div`
  background: transparent;
  @media ${device.tablet} {
    margin-top: 60px;
  }

  @media ${device.phone} {
    margin-top: 0px;
  }
`

const Container = styled.footer`
  min-height: 500px;
  margin-top: 0px;
  padding: 32px;
  background: ${props => props.theme.colors.green};

  .sitemap-url {
    display: block;
    margin: 12px 0;
    color: #fff;
    font-size: 1.2rem;
    width: fit-content;
    &:hover {
      color: #000;
    }
  }
`

const SVG = styled.svg`
  margin-bottom: -10px;
  margin-top: ${props => props.withStores ? '-260px' : '-142px'};
`

const StyledLink = styled(Link)`
  display: block;
  margin: 12px 0;
  color: #fff;
  font-size: 1.2rem;
  width: fit-content;
  &:hover {
    color: #fff;
  }
`

const Title = styled.h3`
  color: #fff;
`

const StyledParagraph = styled.p`
  margin: 6px 0;
  color: #fff;
  font-size: 1.2rem;
  a {
    color: #fff;
  }
`

function Footer({ withStores }) {
  const { dispatch } = useContext(FilterContext)
  const categories = [
    { category: "T-shirts", url: `tshirts` },
    { category: "Jakker", url: `jakker` },
    { category: "Bukser", url: `bukser` },
    { category: "Skjorter", url: `skjorter` },
    { category: "Trøjer", url: `trojer` },
    { category: "Tasker", url: `tasker` },
    { category: "Sport", url: `sport` },
    { category: "Sko", url: `sko` },
    { category: "Undertøj", url: `undertoj` },
    { category: "Accessories", url: `accessories` },
  ]

  const handleClick = () => dispatch({ resetState: true })

  const handleCategory = gender => {
    if (gender === "men") {
      return (
        <>
          {categories.map(category => (
            <StyledLink
              key={category.url}
              onClick={() => handleClick()}
              to={`/herre/${category.url}`}
            >
              {category.category}
            </StyledLink>
          ))}
        </>
      )
    }

    return (
      <>
        {categories.map(category => (
          <StyledLink
            key={category.url}
            onClick={() => handleClick()}
            to={`/dame/${category.url}`}
          >
            {category.category}
          </StyledLink>
        ))}
      </>
    )
  }

  return (
    <Wrapper background={withStores}>
      {withStores && <Brands />}
      <SVG withStores={withStores} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ff6347"
          fillOpacity="1"
          d="M0,192L120,208C240,224,480,256,720,256C960,256,1200,224,1320,208L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </SVG>
      <Container>
        <Grid>
          <Grid.Column mobile={8} tablet={8} computer={4}>
            <Title>Tøj til herre</Title>
            {handleCategory("men")}
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={4}>
            <Title>Tøj til damer</Title>
            {handleCategory("women")}
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={4}>
            <Title>Lineout</Title>
            <StyledLink to="/om-os">Om os</StyledLink>
            <StyledLink to="/cookies">Cookies</StyledLink>
            <a
              className="sitemap-url"
              rel="noreferrer"
              href="/sitemap.xml"
              target="_blank"
            >
              Sitemap
            </a>
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={4}>
            <Title>Mangler din shop?</Title>
            <StyledParagraph>
              <a href="mailto:info@midear.dk">Kontakt os</a>
            </StyledParagraph>
          </Grid.Column>
        </Grid>
      </Container>
    </Wrapper>
  )
}

export default Footer
