const partners = [
  {
    partner: "ASOS",
    logo: "https://d361yhx0lnxc34.cloudfront.net/images/a/asos_9.png",
  },
  {
    partner: "Nike Store",
    logo:
      "https://i.pinimg.com/236x/11/24/cb/1124cbfd38748379306d4848611b707f--nike-swoosh-logo-beauty-logo.jpg",
  },
  {
    partner: "Miinto",
    logo: "https://resources.koongo.com/ChannelCacheKaas/miinto/logo.png",
  },
  {
    partner: "Rains",
    logo:
      "https://cdn.shopify.com/s/files/1/1123/3534/collections/RAINS-LOGO_large.png?v=1597142045",
  },
  {
    partner: "Kaufmann",
    logo: "https://www.web2media.dk/images/kaufmann_logo.png",
  },
  {
    partner: "KingsQueens",
    logo: "https://cashy.dk/storage/122/mfysq2ejfh3cgplvt2mhnicvtauslo.png",
  },
  {
    partner: "Quint",
    logo: "https://astc.dk/wp-content/uploads/2017/02/quint.jpg",
  },
  {
    partner: "Basic Apparel",
    logo:
      "https://www.kraes.com/images/41338150_1538283542938524_1034937623409328128_n.jpg",
  },
  {
    partner: "Dresslab",
    logo:
      "https://dresslab.dk/wp-content/uploads/2020/03/dresslab-logo-midt.jpg",
  },
  {
    partner: "Neats",
    logo: "https://neats.dk/cache/748472/fit-276x48x75.jpg",
  },
  {
    partner: "Veraldo",
    logo:
      "https://cdn.shopify.com/s/files/1/0575/1781/files/Llmfx-tL_400x.png?v=1574343060%201x,%20//cdn.shopify.com/s/files/1/0575/1781/files/Llmfx-tL_800x.png?v=1574343060%202x",
  },
  // Men
  {
    partner: "2trendy",
    logo: "https://www.2trendy.dk/images/2trendy/2trendy-logo-03-05-16-3.png",
  },
  {
    partner: "Balder Clothes",
    logo:
      "https://balderclothes.com/wp-content/uploads/2020/04/cropped-logo-2020-balderclothes-1-6.png",
  },
  {
    partner: "Hardcasa",
    logo:
      "https://www.hardcasa.dk/images/hardcasa/hardcasa-logo-23-09-15-UDEN-badge.png",
  },
  {
    partner: "Trendlovers",
    logo: "https://www.trendlovers.dk/images/trendlovers-logo-spa2r.png",
  },
  // Both
  {
    partner: "TeeShoppen",
    logo:
      "https://teeshoppen.dk/skin/frontend/base/default/images/teeshoppen-logo.svg",
  },
]

module.exports.partners = partners
