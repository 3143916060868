import React, { useContext, useState } from "react"
import styled from "styled-components"
import { Menu, Icon } from "semantic-ui-react"
import { Link } from "gatsby"
import { device } from "../../theme"
import { GenderContext } from "../../providers/GenderProvider"
import useWindowSize from "../../hooks/useWindowSize"
import MobileMenu from "./MobileMenu"
import logo from "../../images/lineoutlogo.svg"
import cookieslogo from "../../images/iconbig.png"

const Container = styled.header`
  position: relative;
  padding: 12px 22px;
  height: 75px;
  padding-left: 280px;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    padding-left: 22px;
    justify-content: space-between;
  }

  & .active.item {
    border-color: #fff !important;
    color: ${props => props.theme.colors.green} !important;
  }
`

const StyledMenu = styled(Menu)`
  border-bottom: none !important;
  margin: 0 !important;

  @media ${device.desktop} {
    display: none;
  }

  .menu-link {
    font-weight: 400 !important;
  }
`

const StyledLink = styled(Link)`
  position: ${props => (props.isSticky ? "fixed" : "absolute")};
  left: 22px;
  margin: 0;
  z-index: 10;
  color: ${props => props.theme.colors.black} !important;
  top: ${props => (props.isSticky ? "20px" : "50%")};
  transform: ${props => (props.isSticky ? "none" : "translateY(-50%)")};

  @media ${device.tablet} {
    position: initial;
    transform: none;
  }
`

const BurgerIcon = styled(Icon)`
  float: right;
  cursor: pointer;
  display: none;
  @media ${device.desktop} {
    display: none;
  }
`

const Logo = styled.img`
  height: 30px;
  margin: 0;
`

const CookieIcon = styled.a`
  line-height: 0.8 !important;
  img {
    width: 22px !important;
    margin-bottom: 0;
  }
`

const Header = ({ isSticky }) => {
  const [width] = useWindowSize()
  const { gender, setGender } = useContext(GenderContext)
  const [isOpen, setOpen] = useState(false)
  return (
    <Container>
      <StyledLink isSticky={isSticky} onClick={() => setGender(null)} to="/">
        <Logo src={logo} alt="" />
      </StyledLink>
      {width <= 1024 ? (
        <>
          <BurgerIcon
            onClick={() => setOpen(!isOpen)}
            name={isOpen ? "close" : "bars"}
            size="large"
          />
          <MobileMenu isOpen={isOpen} />
        </>
      ) : (
        <StyledMenu pointing secondary>
          <>
            <Menu.Item
              as={Link}
              className="menu-link"
              to="/herre"
              name="Herre"
              active={gender === "male"}
              onClick={() => setGender("male")}
            />
            <Menu.Item
              as={Link}
              className="menu-link"
              to="/dame"
              name="Dame"
              active={gender === "female"}
              onClick={() => setGender("female")}
            />
          </>
          <Menu.Menu position="right">
            <Menu.Item
              as={Link}
              name="Mine favoritter"
              to="/mine-favoritter"
              active={window.location.href.match("mine-favoritter")}
              onClick={() => setGender(null)}
            />
          </Menu.Menu>
        </StyledMenu>
      )}
    </Container>
  )
}

export default Header
