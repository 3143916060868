import React, { useContext } from "react"
import styled from "styled-components"
import Fade from "react-reveal"
import { Link } from "gatsby"
import { GenderContext } from "../../providers/GenderProvider"
import { device } from "../../theme"
import cookieslogo from "../../images/iconbig.png"

const Container = styled.div`
  position: fixed;
  z-index: 1000;
  background: ${props => props.theme.colors.lightgrey};
  top: 75px;
  right: 0;
  bottom: 0;
  width: 100%;
  transform: ${props => (props.isOpen ? "translateX(0%)" : "translateX(100%)")};
  transition: transform 0.2s ease-in;
  padding: 0 22px;
  max-width: 320px;

  @media ${device.phone} {
    max-width: calc(50% - 8px);
  }

  @media ${device.desktop} {
    display: none;
  }
`

const StyledLink = styled(Link)`
  display: block;
  color: #444;
  font-size: 1rem;
  margin: 22px 0;

  &.active {
    color: ${props => props.theme.colors.green};
  }
`

function MobileMenu({ isOpen }) {
  const { gender, setGender } = useContext(GenderContext)
  return (
    <Container isOpen={isOpen}>
      {isOpen && (
        <>
          <StyledLink className={gender === "men" ? "active" : ""} to="/herre">
            Herre
          </StyledLink>
          <StyledLink className={gender === "women" ? "active" : ""} to="/dame">
            Dame
          </StyledLink>
          <StyledLink
            className={
              window.location.href.match("mine-favoritter") ? "active" : ""
            }
            onClick={() => setGender(null)}
            to="/mine-favoritter"
          >
            Mine favoritter
          </StyledLink>
        </>
      )}
    </Container>
  )
}

export default MobileMenu
